<template>
  <div>
    <span class="total">{{ $t("total") }} {{ total }} {{ $t("result") }}</span>
  </div>
</template>

<script>
export default {
  props: {
    total: {}
  }
};
</script>

<style lang="sass" scoped>
.total
	font-size: 16px
	line-height: 12px
	letter-spacing: 0.045em
	color: #4E5471
</style>